import { ICashBox, IRequisite } from '../../../../types'

export enum RequisiteType {
  'SBER' = 'SBER',
  'TINKOFF' = 'TINKOFF',
  'YOOMONEY' = 'YOOMONEY',
  'MC/VISA UAH' = 'MC/VISA UAH',
  'PRIVAT24 P2P UAH' = 'PRIVAT24 P2P UAH',
  'RAIFFEISEN' = 'RAIFFEISEN',
  'ALFABANK' = 'ALFABANK',
}

export enum Currency {
  RUB = 'RUB',
  UAH = 'UAH',
  AZN = 'AZN',
  NOT_TON = 'NOT(TON)',
  USDC_ETH = 'USDC(ETH)',
  ETH = 'ETH(ETH)',
  USDT_TRX = 'USDT(TRX)',
  USDT_TON = 'USDT(TON)',
  TON = 'TON(TON)',
  LTC = 'LTC(LTC)',
  BTC = 'BTC(BTC)',
}

export interface ErrorObject {
  cashBoxId: string
  requisiteType: string
  cardNumber: string
  phone: string
  cardholderName: string
  comment: string
  paymentLimitByAmount: string
  general_error: string
  commission: string
}

export interface RequisiteListResponse {
  success: boolean
  requisites: Array<IRequisite>
  totalRows: number
  errors: Partial<ErrorObject>
}

export interface RequisiteEdit {
  id?: number
  accountNumber?: string | null
  cashBox: ICashBox
  cashBoxId: number | string
  requisiteType: RequisiteType | string
  name: string
  phone?: string | null
  cardNumber: string
  cardholderName: string
  currency?: Currency
  paymentLimitByAmount: number
  paymentLimitByTicketCount: number
  paymentMethods: string[]
  comment: string
  paymentUrl?: string
  acquiringBank: string
}

export interface RequisiteEditResponse {
  success: boolean
  requisite: IRequisite
  totalRows: number
  errors: Partial<ErrorObject>
}

export interface RequisiteTotalSumResponse {
  sum: number
  totalRows: number
  success: boolean
  errors: Partial<ErrorObject>
}

export interface RequisiteToggleResponse {
  success: boolean
  requisite: IRequisite
  totalRows: number
  errors: Partial<ErrorObject>
}
