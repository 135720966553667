import { ChangeEvent, useEffect, useState } from 'react'
import { Button, FormLabel, Modal, Spinner } from 'react-bootstrap'
import clsx from 'clsx'

import { setLocale } from 'yup'
import { ru } from 'yup-locales'

import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import { getPaymentAdapterList } from '../../api/getPaymentAdapterList'
import { patchAdapter } from '../../api/patchAdapter'
import { IPaymentAdapter } from '../../../../../types'
import { Table } from '../../../../../components/Table'
import { TableHead } from '../../../../../components/Table/TableHead'
import { TableBody } from '../../../../../components/Table/TableBody'
import { convertUTCtoMSK } from '../../../../../utils'
import { getPaymentAdapterStatus } from '../../api/getPaymentAdapterStatus'

setLocale(ru)

const errorStyle = { color: 'red' }

const breadCrumbs: Array<PageLink> = [
  {
    title: 'Платежные адаптеры',
    path: '/settings/payment-adapters',
    isSeparator: false,
    isActive: true,
  },
]

const PaymentAdapters = () => {
  const [errorAPI, setErrorAPI] = useState('')
  const [paymentAdaptersList, setPaymentAdaptersList] = useState<IPaymentAdapter[]>([])
  const [loadingAdapters, setLoadingAdapters] = useState<{ [key: string]: boolean }>({})
  const [adaptersStatus, setAdaptersStatus] = useState<{ [key: string]: 'none' | '204' | '404' }>(
    {}
  )
  const [show, setShow] = useState(false)
  const [activeAdapter, setActiveAdapter] = useState<IPaymentAdapter>()
  const [minSum, setMinSum] = useState<string>('')

  const handleClose = () => {
    setShow(false)
  }

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setMinSum(e.target.value)
  }

  const submitHandler = async () => {
    if (activeAdapter?.id) {
      const data = {
        name: activeAdapter.name,
        type: activeAdapter.type,
        amountRequired: activeAdapter.amountRequired,
        minDeposit: Number(minSum),
      }
      const response = await patchAdapter(activeAdapter.id, data)
      if (response.status === 200) {
        setShow(false)
        setMinSum('')
      }

      const res = await getPaymentAdapterList()
      if (res.data.success && res.data.paymentAdapters) {
        setPaymentAdaptersList(res.data.paymentAdapters)
      }
    }
  }

  const handleShow = (adapter: any) => {
    setActiveAdapter(adapter)
    setShow(true)
  }

  const fetchPaymentAdapterList = async () => {
    const response = await getPaymentAdapterList()

    if (!response.data) {
      return false
    }

    if (response.data.success && response.data.paymentAdapters) {
      setPaymentAdaptersList(response.data.paymentAdapters)
      setErrorAPI('')

      response.data.paymentAdapters.forEach((paymentAdapter) => {
        if (paymentAdapter.enabled) {
          setLoadingAdapters((prevState) => ({ ...prevState, [paymentAdapter.id]: true }))
          setAdaptersStatus((prevState) => ({ ...prevState, [paymentAdapter.id]: 'none' }))

          fetchAdapterStatus(paymentAdapter.id)
        }
      })
    } else if (response.data.errors) {
      setPaymentAdaptersList([])

      setErrorAPI('Возникла ошибка: ' + JSON.stringify(response.data.errors))
    }
  }

  const fetchAdapterStatus = async (id: number | string) => {
    const response = await getPaymentAdapterStatus(id)

    setLoadingAdapters((prevState) => ({ ...prevState, [id]: false }))
    setAdaptersStatus((prevState) => ({
      ...prevState,
      [id]: response.status.toString() as '204' | '404',
    }))
  }

  useEffect(() => {
    fetchPaymentAdapterList()
  }, [])

  return (
    <div>
      <div className='card sticky-top-tickets mb-7'>
        <PageTitle breadcrumbs={breadCrumbs} />
        <div className='card-header border-0 pt-5 pb-2'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Платежные адаптеры</span>
          </h3>
        </div>
      </div>

      <div className='card'>
        <div className='card-body'>
          {paymentAdaptersList.length ? (
            <Table>
              <TableHead>
                <tr>
                  <th className='min-w-120px'>Id</th>
                  <th className='min-w-150px'>Название адаптера</th>
                  <th className='min-w-150px'>Дата создания</th>
                  <th className='min-w-120px'>Тип</th>
                  <th className='min-w-120px'>Статус</th>
                  <th className='min-w-120px'>Минимальная сумма</th>
                  <th className='min-w-120px'>Сумма обязательна</th>
                  <th />
                </tr>
              </TableHead>
              <TableBody>
                {paymentAdaptersList.map((paymentAdapter, index) => (
                  <tr
                    key={index}
                    className={clsx({
                      'bg-success': adaptersStatus[paymentAdapter.id] === '204',
                      'bg-danger': adaptersStatus[paymentAdapter.id] === '404',
                    })}
                  >
                    <td>{paymentAdapter.id}</td>
                    <td>{paymentAdapter.name}</td>
                    <td>{convertUTCtoMSK(paymentAdapter.createdAt.toString())}</td>
                    <td>{paymentAdapter.type}</td>
                    <td>
                      {loadingAdapters[paymentAdapter.id] ? (
                        <Spinner />
                      ) : adaptersStatus[paymentAdapter.id] === '204' ? (
                        'Active'
                      ) : (
                        'Disabled / offline'
                      )}
                    </td>
                    <td>{paymentAdapter.minDeposit}</td>
                    <td>
                      <span>{paymentAdapter.amountRequired ? 'ДА' : 'НЕТ'}</span>
                    </td>
                    <td className='min-w-40px'>
                      <img
                        src='/media/svg/general/settings.svg'
                        alt='settings'
                        width='30px'
                        onClick={() => handleShow(paymentAdapter)}
                        style={{ cursor: 'pointer' }}
                      />
                    </td>
                  </tr>
                ))}
              </TableBody>
            </Table>
          ) : (
            'Идет загрузка...'
          )}
        </div>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Настройки {activeAdapter?.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <FormLabel>Минимальный депозит</FormLabel>
              <input
                onChange={changeHandler}
                className='form-control'
                placeholder=''
                value={minSum}
                type='number'
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>
              Отмена
            </Button>
            <Button variant='primary' onClick={submitHandler}>
              Сохранить
            </Button>
          </Modal.Footer>
        </Modal>
        {errorAPI && (
          <div className='row mb-6 fw-bold fs-6 border-top p-9' style={errorStyle}>
            {errorAPI}
          </div>
        )}
      </div>
    </div>
  )
}

export default PaymentAdapters
