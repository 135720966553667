import React from 'react'
import { useIntl } from 'react-intl'

type Props = {
  className: string
  color: string
  status: string
  count: number
  commission?: string | null
  sum: string
}

const ReportBlock: React.FC<Props> = ({ className, color, status, count, commission, sum }) => {
  const intl = useIntl()

  return (
    <div className={`card ${className}`}>
      <div className='card-body p-0'>
        <div className={`px-9 pt-7 card-rounded h-275px w-100 bg-${color}`}>
          <h3 className='m-0 text-white fw-bold fs-1 text-center'>
            {intl.formatMessage({ id: 'ticket.status.' + status })}
          </h3>
          <div className='d-flex text-center flex-column text-white pt-8'>
            <span className='fw-semibold fs-3'>Количество: </span>
            <span className='fw-semibold fs-1'>{count}</span>
          </div>
          <div
            className={`d-flex text-center text-white pt-8 ${
              commission ? 'justify-content-between' : 'justify-center'
            }`}
          >
            <div className='d-flex flex-column'>
              <span className='fw-semibold fs-3'>Сумма: </span>
              <span className='fw-bold fs-2x pt-1'>{sum}</span>
            </div>
            {commission ? (
              <div className='d-flex flex-column'>
                <span className='fw-semibold fs-3'>Комиссия: </span>
                <span className='fw-bold fs-2x pt-1'>{commission}</span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export { ReportBlock }
