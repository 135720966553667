import { ETicketStatus } from '../../../../types/enums'
import { Currency, ErrorObject, RequisiteType } from '../../settings/core/_models'

export interface TicketListObject {
  [token: string]: PaymentTicket
}

export interface ReportBlockItems {
  [token: string]: Array<ReportBlockItem>
}

export enum PaymentTicketProcessType {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
  NOT_PROCESSED = 'NOT_PROCESSED',
}

export interface IssuedRequisite {
  requisiteType: RequisiteType
  cardNumber: string
  currency: Currency
  name: string
  createdAt: string
}

export interface Sms {
  id: number
  text: string
  receivingDate: string
  phoneFrom: string
  createdAt: string
}

export interface TicketMerchant {
  token: string
  title: string
  enabled: boolean
  createdAt: string
}

export interface TicketCashBox {
  id: number
  title: string
  createdAt: string
}

export interface TicketExecutor {
  id: number
  uuid: string
  enabled: boolean
  createdAt: string
}

export interface ExternalUser {
  id: number
  token: string
  externalId: string
  createdAt: string
}

export type TTicketSource = {
  name: 'MANUAL' | 'SMS' | 'MERCHANT'
  value: 'MANUAL' | 'SMS' | 'MERCHANT'
}

export interface ExternalTransaction {
  createdAt: string
  paymentAdapterName: string
  paymentAdapterType: string
  paymentId: string
}

export interface PaymentTicket {
  status: ETicketStatus
  issuedRequisite: IssuedRequisite
  sms: Sms | null
  requisiteType: RequisiteType
  currency: Currency
  merchant: TicketMerchant
  completionDate: string
  amount: number
  bill: string
  notifiedDate: string | null
  comment: string | null
  token: string
  callbackUrl: string
  executor: TicketExecutor | null
  createdAt: string
  operationDate?: string
  cashBox?: TicketCashBox
  externalUser?: ExternalUser
  externalTransactions?: ExternalTransaction[]
  lastExternalTransaction?: ExternalTransaction
  processType: string
  unknownPaymentTicket: boolean
  source: TTicketSource
  transactionId: string
}

export interface ReportBlockItem {
  status: 'ACTIVE' | 'IN_PROGRESS' | 'COMPLETED' | 'DECLINED'
  ticketsCount: number
  sum: number
  commission_sum: string
}

export interface TicketListResponse {
  success: boolean
  tickets: Array<PaymentTicket>
  totalRows: number
  errors: Partial<ErrorObject>
}

export interface TicketActionResponse {
  success: boolean
  ticket: Partial<PaymentTicket>
  errors: Partial<ErrorObject>
  totalRows: number
}

export interface ReportTicketsResponse {
  success: boolean
  data: Array<ReportBlockItem>
  totalRows: number
  errors: Partial<ErrorObject>
}

export interface ParamsTicketsRequest {
  euConfirmed?: boolean
  ticketToken?: string
  status: Array<ETicketStatus> | string
  page: number
  perPage: number
  createdDateStart?: string
  createdDateEnd?: string
  merchantKey?: string
  cashBoxId?: number
  ticketProcessType?: string
  unknownPayment?: boolean
}

export function PrepareTicketsToObject(tickets: Array<PaymentTicket>): TicketListObject {
  let object: TicketListObject = {}

  tickets.forEach((item) => {
    object[item.token] = item
  })

  return object
}
