import { useEffect, useState } from 'react'
import Select from 'react-select'

import { SelectData, SelectOptionString } from './models'
import { getListMerchantsForSelect } from '../core/_requests'
import { Merchant } from '../core/_models'
import { useAuth } from '../../modules/auth'
import { SelectStyles } from '../../../components/Select'
import { useThemeMode } from '../../../_metronic/partials'

export const selectType = 'merchant'

const MerchantFilter = ({
  changeSelect,
  value,
  isMultiple,
  preloadData,
}: {
  changeSelect: (selectData: SelectData) => void
  value: string[]
  isMultiple?: boolean
  preloadData?: Array<Merchant>
}) => {
  const { mode: theme } = useThemeMode()
  const { currentUser } = useAuth()
  const [options, setOptions] = useState<Array<SelectOptionString>>([])
  const [loading, setLoading] = useState(true)

  const updateListMerchants = async () => {
    let response = await getListMerchantsForSelect(currentUser)

    if (response.data.success && response.data.merchants) {
      let merchants: Array<SelectOptionString> = []

      response.data.merchants.forEach((value: Merchant) => {
        const item = {
          value: value.token,
          label: value.title,
        }

        merchants.push(item)
      })

      setOptions(merchants)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!preloadData) {
      updateListMerchants()
    } else {
      let merchants: Array<SelectOptionString> = []
      preloadData.forEach((value: Merchant) => {
        const item = {
          value: value.token,
          label: value.title,
        }
        merchants.push(item)
      })
      setOptions(merchants)
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (selectedData: any) => {
    const values = !selectedData
      ? []
      : !isMultiple
      ? [selectedData.value]
      : selectedData.map((item: SelectOptionString) => item.value)

    changeSelect({
      value: values,
      type: selectType,
    })
  }

  return loading ? (
    <>Загрузка мерчантов...</>
  ) : (
    <Select
      value={options.filter((o: SelectOptionString) => value?.find((sv) => sv === o.value)) || ''}
      onChange={handleChange}
      isClearable={options.some((v) => !v.isFixed)}
      isMulti={isMultiple}
      options={options}
      placeholder={'Выберите мерчанта'}
      styles={SelectStyles(theme)}
    />
  )
}

export default MerchantFilter
